<template>
  <div class="m1">
    <div class="m1-h">
      <h2>用户列表</h2>
      <div class="m1-btns">
        <el-button type="primary" @click="dialogVisible = true">新建</el-button>
      </div>
    </div>
    <el-table
      border
      :data="tableData"
      style="width: 100%">
      <el-table-column
        prop="user_name"
        label="管理员ID"
        width="180">
      </el-table-column>
      <el-table-column
        prop="group_name"
        label="类别"
        width="180">
      </el-table-column>
      <el-table-column
        prop="rule"
        label="权限">
      </el-table-column>
      <el-table-column
        label="操作">
          <template slot-scope="scope">
            <a v-if="scope.row.role_id == 2" @click="dialogVisible2 = true; form2.id = scope.row.id; form2.rule_ids = scope.row.rule_ids" href="javascript:;" style="margin-right: 10px">修改权限</a>
            <a @click="dialogVisible3 = true; form3.password = ''; form3.id = scope.row.id" href="javascript:;" style="margin-right: 10px">修改密码</a>
            <a @click="toDelete(scope.row.id)" v-if="scope.row.role_id != 1" href="javascript:;">删除</a>
          </template>
        
      </el-table-column>
    </el-table>

    <el-dialog
      title="新建管理员"
      :visible.sync="dialogVisible"
      width="50%">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="账户ID">
          <el-input v-model="form.user_name"></el-input>
        </el-form-item>
        <el-form-item label="密码">
          <el-input v-model="form.password"></el-input>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="form.role_id" placeholder="请选择类型">
            <el-option label="一般权限" value="2"></el-option>
            <el-option label="数据导入" value="3"></el-option>
          </el-select>
          <el-checkbox-group v-model="form.rule_ids" v-if="form.role_id != '3'">
            <el-checkbox label="2">用户管理</el-checkbox>
            <el-checkbox label="3">财务管理</el-checkbox>
            <el-checkbox label="4">招聘信息管理</el-checkbox>
            <el-checkbox label="5">异常管理</el-checkbox>
            <el-checkbox label="6">设置</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toSave()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="修改权限"
      :visible.sync="dialogVisible2"
      width="50%">
        <el-checkbox-group v-model="form2.rule_ids">
          <el-checkbox label="2">用户管理</el-checkbox>
          <el-checkbox label="3">财务管理</el-checkbox>
          <el-checkbox label="4">招聘信息管理</el-checkbox>
          <el-checkbox label="5">异常管理</el-checkbox>
          <el-checkbox label="6">设置</el-checkbox>
        </el-checkbox-group>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="edit()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="修改密码"
      :visible.sync="dialogVisible3"
      width="50%">
        <el-input v-model="form3.password"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="toResetPassword()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  list, save, resetPassword, delete1
} from '@/api/home';
export default {
  name: 'index',
  data() {
    return {
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      form: {
        user_name: '',
        password: '',
        role_id: '',
        rule_ids: []
      },
      form2: {
        id: '',
        rule_ids: []
      },
      form3: {
        password: '',
        id: ''
      },
      tableData: []
    }
  },
  computed: {
  },
  created() {
    
  },
  mounted(){
    this.getList()
  },
  methods: {
    getList () {
      list().then(res =>{
        this.tableData = res.data.data.data
      })
    },
    toSave () {
      if (this.form.role_id == '3') {
        this.form.role_ids = []
      }
      save(this.form).then(res =>{
        if (res.data.msg == 'ok') {
          this.$message({
            type: 'success',
            message: '添加成功'
          });
          this.dialogVisible = false;
          this.getList()
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          });   
        }
      })
    },
    edit () {
      save(this.form2).then(res =>{
        if (res.data.msg == 'ok') {
          this.dialogVisible2 = false;
          this.getList()
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          });   
        }
        
      })
    },
    toResetPassword () {
      resetPassword(this.form3).then(res =>{
        if (res.data.msg == 'ok') {
          this.dialogVisible3 = false;
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          });   
        }
      })
    },
    toDelete (id) {
      this.$confirm('是否确认删除', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        delete1({
          ids: id
        }).then(res =>{
          this.getList()
        })
      }).catch(() => {
                  
      });
      
    },
  },
}
</script>
<style scoped lang="scss">
.m1-h{
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
</style>
